import { useUserId } from "$components/common/AuthWrapper/context";
import { GET_PREMIUM_STATUS } from "$frontend/query/user";
import { skipToken, useSuspenseQuery } from "@apollo/client";
export function usePremiumStatus() {
    var _status_user_premium_by_pk;
    let id = useUserId(), { data: status } = useSuspenseQuery(GET_PREMIUM_STATUS, id ? {
        variables: {
            user_id: id
        }
    } : skipToken);
    return null != (_status_user_premium_by_pk = null == status ? void 0 : status.user_premium_by_pk) ? _status_user_premium_by_pk : null;
}
