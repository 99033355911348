import { useCompute } from "./useCompute";
export function useTextAnimation(text) {
    return useCompute((text)=>{
        let output = [], map = new Map();
        for (let item of text.split("")){
            var _map_get;
            let count = null != (_map_get = map.get(item)) ? _map_get : 0;
            map.set(item, count + 1), output.push({
                token: item,
                key: item + "-" + count
            });
        }
        return output;
    }, text);
}
